import React from 'react';
import { css } from 'styled-components';

import useTranslation from '../../../locales/useTranslation';

import Section from '../../common/Section';

import filterSectionPt from '../../../images/filterSectionPt.svg';
import filterSectionEn from '../../../images/filterSectionEn.svg';

const filterCss = css`
  padding: 220px 48px 110px 40px;
  flex-wrap: wrap-reverse !important;
  margin: 0 !important;

  @media (max-width: 922px) {
    padding: 100px 32px 100px;
  }
`;

const titleCss = css`
  @media (max-width: 992px) {
    font-size: 30px;
    line-height: 35px;
    text-align: center !important;
  }
`;

const textCss = css`
  @media (max-width: 992px) {
    text-align: center !important;
  }
`;

const FilterSection = () => {
  const { t, isPortuguese } = useTranslation();

  return (
    <Section
      sectionTitle={t['Analysis']}
      title={t['DiscoverTheWeaknessesOfYourApp']}
      description={t['SeeWhichPartsOfYourPlatform']}
      sectionTitleProps={{ css: textCss }}
      titleProps={{ css: titleCss }}
      descriptionProps={{ css: textCss }}
      img={isPortuguese ? filterSectionPt : filterSectionEn}
      imgAlignment="left"
      imgColProps={{
        md: 24,
        lg: 10,
        xl: 10,
      }}
      contentColProps={{
        md: 24,
        lg: 12,
        xl: 12,
      }}
      gutter={[0, 32]}
      css={filterCss}
    />
  );
};

export default FilterSection;
