import React, { useState } from 'react';
import styled, { FlattenInterpolation, css } from 'styled-components';
import { Row, Col as AntdCol } from 'antd';
import { RowProps } from 'antd/lib/grid/row';
import { ColProps } from 'antd/lib/grid/col';

import Text, { TextProps } from './Text';
import Space from './Space';

const Container = styled(Row).attrs({
  justify: 'space-between',
  align: 'middle',
})<{ imageIsLoaded: boolean; css?: FlattenInterpolation<any>; minHeight?: string }>`
  ${p => p.css}

  ${p =>
    p.minHeight &&
    css`
      min-height: ${p.minHeight};

      @media (max-width: 992px) {
        min-height: unset;
      }
    `}
`;

const Col = styled(AntdCol)<{ css?: FlattenInterpolation<any> }>`
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  ${p => p.css}
`;

const Image = styled.img.attrs({
  loading: 'lazy',
})<{ css?: FlattenInterpolation<any> }>`
  width: 100%;
  ${p => p.css}
`;

interface SectionProps extends RowProps {
  css?: FlattenInterpolation<any>;
  contentColCss?: FlattenInterpolation<any>;
  imageColCss?: FlattenInterpolation<any>;
  imageCss?: FlattenInterpolation<any>;
  sectionTitle?: string;
  sectionTitleProps?: TextProps;
  title?: string;
  customTitle?: React.ReactNode;
  titleProps?: TextProps;
  description: string;
  descriptionProps?: TextProps;
  ImgWrapper?({ children }): JSX.Element;
  img: string;
  imgAlt?: string;
  imgAlignment?: 'right' | 'left';
  children?: React.ReactNode;
  contentColProps?: ColProps;
  imgColProps: ColProps;
  minHeight?: string;
}

const Section = ({
  sectionTitle,
  sectionTitleProps = {},
  title,
  customTitle,
  titleProps = {},
  description,
  descriptionProps = {},
  ImgWrapper,
  img,
  imgAlt,
  imgAlignment = 'right',
  contentColProps = {},
  imgColProps = {},
  children,
  contentColCss,
  imageColCss,
  imageCss,
  ...props
}: SectionProps) => {
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  return (
    <Container imageIsLoaded={imageIsLoaded} {...props}>
      {imgAlignment === 'left' && (
        <Col css={imageColCss} {...imgColProps}>
          <Row justify="start">
            {ImgWrapper ? (
              <ImgWrapper>
                <Image
                  src={img}
                  alt={sectionTitle || imgAlt || title}
                  onLoad={() => setImageIsLoaded(true)}
                  css={imageCss}
                />
              </ImgWrapper>
            ) : (
              <Image
                src={img}
                alt={sectionTitle || imgAlt || title}
                onLoad={() => setImageIsLoaded(true)}
                css={imageCss}
              />
            )}
          </Row>
        </Col>
      )}

      <Col css={contentColCss} {...contentColProps}>
        {sectionTitle && (
          <>
            <Text font="sectionTitle" color="lightBlue" {...sectionTitleProps}>
              {sectionTitle}
            </Text>
            <Space height={12} />
          </>
        )}

        {customTitle ? (
          customTitle
        ) : (
          <Text font="h2" lineHeight="60px" color="darkBlue" {...titleProps}>
            {title}
          </Text>
        )}

        <Space height={24} />
        <Text font="link" lineHeight="22px" {...descriptionProps}>
          {description}
        </Text>
        {children}
      </Col>

      {imgAlignment === 'right' && (
        <Col css={imageColCss} {...imgColProps}>
          <Row justify="end">
            {ImgWrapper ? (
              <ImgWrapper>
                <Image
                  src={img}
                  alt={sectionTitle || imgAlt || title}
                  onLoad={() => setImageIsLoaded(true)}
                  css={imageCss}
                />
              </ImgWrapper>
            ) : (
              <Image
                src={img}
                alt={sectionTitle || imgAlt || title}
                onLoad={() => setImageIsLoaded(true)}
                css={imageCss}
              />
            )}
          </Row>
        </Col>
      )}
    </Container>
  );
};

export default Section;
