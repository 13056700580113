import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'antd';

import useTranslation from '../../../locales/useTranslation';
import useHistory from '../../../locales/useHistory';

import Space from '../../common/Space';
import Section from '../../common/Section';
import Text from '../../common/Text';

import dataDrivenSection from '../../../images/dataDrivenSection.svg';

const dataDrivenCss = css`
  padding: 40px 95px 48px 40px;
  background: ${p => p.theme.color.darkBlue};
  margin: 0 !important;

  @media (max-width: 992px) {
    padding: 48px 27px;
  }
`;

const textCss = css`
  @media (max-width: 992px) {
    text-align: center !important;
  }
`;

const titleCss = css`
  @media (max-width: 992px) {
    font-size: 35px;
    line-height: 40px;
    text-align: center !important;
    font-weight: 800;
  }
`;

const heroCss = css`
  @media (max-width: 992px) {
    display: none;
  }
`;

const ImageCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
  /* height: 433px; */
  background: #354675;
  border-radius: 50%;

  > img {
    width: 84%;
  }
`;

const DataDrivenSection = () => {
  const { t, isPortuguese } = useTranslation();
  const history = useHistory();

  const ImageWrapper = ({ children }) => {
    return <ImageCircle>{children}</ImageCircle>;
  };

  return (
    <Section
      customTitle={
        isPortuguese ? (
          <Text font="h2" color="white" fontWeight="600" lineHeight="65px" css={titleCss}>
            Fortaleça sua cultura{' '}
            <Text font="h2" color="white" fontWeight="900" span css={titleCss}>
              Data Driven
            </Text>
          </Text>
        ) : (
          <Text font="h2" color="white" fontWeight="600" lineHeight="65px" css={titleCss}>
            Strengthen your{' '}
            <Text font="h2" color="white" fontWeight="900" span css={titleCss}>
              Data Driven
            </Text>{' '}
            culture
          </Text>
        )
      }
      contentColProps={{
        md: 24,
        lg: 12,
        xl: 12,
      }}
      imgColProps={{ span: 10 }}
      description={t['EveryDayYourPlatform']}
      descriptionProps={{ color: 'white', css: textCss }}
      ImgWrapper={ImageWrapper}
      img={dataDrivenSection}
      imgAlt="Data Driven"
      imageCss={heroCss}
      css={dataDrivenCss}
    >
      <Space height={32} />
      <Button type="default" onClick={() => history.push('/pricing')}>
        {t['GetToKnowOurPricingPlans']}
      </Button>
    </Section>
  );
};

export default DataDrivenSection;
