import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Row, Col } from 'antd';
import {
  TableOutlined as AntdTableOutlined,
  BuildOutlined as AntdBuildOutlined,
  PlayCircleOutlined as AntdPlayCircleOutlined,
} from '@ant-design/icons';

import useTranslation from '../../../locales/useTranslation';

import loyaltyGridPt from '../../../images/loyaltyGridPt.svg';
import loyaltyGridEn from '../../../images/loyaltyGridEn.svg';
import segmentsPt from '../../../images/segmentsPt.svg';
import segmentsEn from '../../../images/segmentsEn.svg';
import userPerformancePt from '../../../images/userPerformancePt.svg';
import userPerformanceEn from '../../../images/userPerformanceEn.svg';

import Text from '../../common/Text';
import Space from '../../common/Space';

const colCss = css`
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
`;

const textCss = css`
  width: 100%;
  @media (max-width: 992px) {
    text-align: center !important;
  }
`;

const titleCss = css`
  @media (max-width: 992px) {
    font-size: 35px;
    line-height: 35px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding-top: 155px;

  @media (max-width: 992px) {
    padding-top: 100px;
  }
`;

const TitleContainer = styled.div`
  padding: 0 24px;
  width: 100%;
  ${colCss}
`;

const Container = styled(Row).attrs({
  justify: 'space-between',
  align: 'middle',
})`
  height: 576px;
  padding: 70px 0 0 125px;

  @media (max-width: 992px) {
    height: unset;
    padding: 64px 28px 0;
  }
`;

const ListItem = styled(Row)<{ active: boolean; mobile: boolean }>`
  background: ${p => (p.active && !p.mobile ? '#f2f6ff' : '#fff')};
  padding: 24px;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.2s;
  align-items: baseline;

  ${p =>
    !p.mobile &&
    css`
      &:hover {
        background: #f2f6ff;
      }
    `}

  @media (max-width: 992px) {
    margin: 0 !important;
    padding: 0;
    width: 100%;
    ${colCss}
  }
`;

const ItemTitle = styled(Row)`
  @media (max-width: 992px) {
    align-items: center !important;
    justify-content: center !important;
    margin-bottom: 8px;
  }
`;

const iconCss = css`
  color: #6d7ea8;
  font-size: 12px;

  @media (max-width: 992px) {
    font-size: 14px;
  }
`;

const TableOutlined = styled(AntdTableOutlined)`
  ${iconCss}
`;

const BuildOutlined = styled(AntdBuildOutlined)`
  ${iconCss}
`;

const PlayCircleOutlined = styled(AntdPlayCircleOutlined)`
  ${iconCss}
`;

const BannerRow = styled(Row)`
  padding: 32px 0 48px;
  @media (min-width: 993px) {
    display: none;
  }
`;

const Banner = styled.img.attrs({
  loading: 'lazy',
})`
  width: 100%;
`;

const UserSegmentation = () => {
  const [mobile, setMobile] = useState(false);
  const [active, setActive] = useState(0);
  const [time, setTime] = useState(Date.now());
  const { t, isPortuguese } = useTranslation();

  const list = [
    {
      icon: TableOutlined,
      title: t['LoyaltyGrid'],
      description: t['ThisGridGroupsYourCustomers'],
      banner: isPortuguese ? loyaltyGridPt : loyaltyGridEn,
    },
    {
      icon: BuildOutlined,
      title: t['Segments'],
      description: t['SegmentYourUsersInAWayThat'],
      banner: isPortuguese ? segmentsPt : segmentsEn,
    },
    {
      icon: PlayCircleOutlined,
      title: t['UserPerformance'],
      description: t['SeeTheEventsThatUsersArePerforming'],
      banner: isPortuguese ? userPerformancePt : userPerformanceEn,
    },
  ];

  const updateWindowDimensions = () => {
    if (window.innerWidth < 992) {
      setMobile(true);
    } else if (window.innerWidth >= 992) {
      setMobile(false);
    }
  };

  useEffect(() => {
    updateWindowDimensions();

    window.addEventListener('resize', updateWindowDimensions);

    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    let interval;
    if (!mobile) {
      interval = setInterval(() => setTime(Date.now()), 10000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [mobile]);

  useEffect(() => {
    if (active === 2) {
      setActive(0);
    } else {
      setActive(active + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <Wrapper>
      <TitleContainer>
        <Text font="sectionTitle" color="lightBlue">
          {t['ComingSoon']}
        </Text>
        <Space height={10} />
        <Text font="h2" color="darkBlue" center css={titleCss}>
          {t['SegmentYourUsers']}
        </Text>
      </TitleContainer>

      <Container>
        <Col md={24} lg={8} xl={8}>
          {list.map(({ icon: Icon, ...item }, index) => (
            <React.Fragment key={item.title}>
              {index > 0 && <Space height={20} />}
              <ListItem
                style={{ flexWrap: 'unset' }}
                active={index === active}
                onClick={() => setActive(index)}
                mobile={mobile}
                gutter={[0, 32]}
              >
                <Col>
                  <ItemTitle align="middle" style={{ flexWrap: 'unset' }}>
                    <Icon />
                    <Space width={10} />
                    <Text font="link" fontWeight="600">
                      {item.title}
                    </Text>
                  </ItemTitle>
                  <Space height={4} />
                  <Text font="link" color="lightBlue" lineHeight="22px" css={textCss}>
                    {item.description}
                  </Text>

                  <BannerRow>
                    <Banner src={item.banner} alt={item.title} />
                  </BannerRow>
                </Col>
              </ListItem>
            </React.Fragment>
          ))}
        </Col>

        {!mobile && (
          <Col md={24} lg={14} xl={14}>
            <Row justify="end">
              <Banner src={list[active].banner} alt={list[active].title} />
            </Row>
          </Col>
        )}
      </Container>
    </Wrapper>
  );
};

export default UserSegmentation;
