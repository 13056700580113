import React from 'react';
import { css } from 'styled-components';

import useTranslation from '../../../locales/useTranslation';

import Section from '../../common/Section';

import crmSection from '../../../images/crmSection.svg';

const crmCss = css`
  padding: 48px 48px 62px;
  flex-wrap: wrap-reverse !important;
  margin: 0 !important;

  @media (max-width: 992px) {
    padding: 100px 27px 100px;
  }
`;

const titleCss = css`
  @media (max-width: 992px) {
    font-size: 30px;
    line-height: 35px;
    text-align: center !important;
  }
`;

const textCss = css`
  @media (max-width: 992px) {
    text-align: center !important;
  }
`;

const CrmSection = () => {
  const { t } = useTranslation();

  return (
    <Section
      sectionTitle={t['ComingSoon']}
      title={t['BoostYourEngagement']}
      description={t['CreatePersonalizedCampaignsBased']}
      sectionTitleProps={{ css: textCss }}
      titleProps={{ css: titleCss }}
      descriptionProps={{ css: textCss }}
      img={crmSection}
      imgAlignment="left"
      imgColProps={{
        md: 24,
        lg: 10,
        xl: 10,
      }}
      contentColProps={{
        md: 24,
        lg: 12,
        xl: 12,
      }}
      gutter={[0, 32]}
      css={crmCss}
    />
  );
};

export default CrmSection;
