import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Row, Col } from 'antd';
import { useSpring, animated, config } from 'react-spring';

import useTranslation from '../../../locales/useTranslation';
import useHistory from '../../../locales/useHistory';

import Space from '../../common/Space';
import Section from '../../common/Section';

import homeHeroPt from '../../../images/homeHeroPt.png';
import homeHeroEn from '../../../images/homeHeroEn.png';

const headerCss = css<{ imageIsLoaded?: boolean }>`
  padding: 146px 24px 0 48px;
  background: #f8f8fa;
  margin: 0 !important;

  @media (max-width: 970px) {
    padding: 192px 32px 0;
  }
`;

const textCss = css`
  @media (max-width: 992px) {
    text-align: center !important;
  }
`;

const titleCss = css`
  @media (max-width: 992px) {
    font-size: 40px;
    line-height: 40px;
    text-align: center !important;
  }
`;

const heroCss = css`
  box-shadow: 0px -4px 20px rgba(17, 30, 66, 0.1);
  border-radius: 10px 10px 0px 0px;
`;

const imageColCss = css`
  padding-bottom: 0 !important;
  @media (max-width: 992px) {
    padding-bottom: 32px !important;
  }
`;

const ButtonWrapper = styled(Row)`
  flex-wrap: wrap-reverse !important;
  @media (max-width: 992px) {
    margin: 0 !important;
  }
`;

const HeroSection = () => {
  const { t, isPortuguese } = useTranslation();
  const history = useHistory();

  const heroSpring = useSpring({
    from: { opacity: 0, transform: 'translateY(711px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: config.slow,
    delay: 100,
  });

  const imageSpring = useSpring({
    from: { opacity: 0, transform: 'translateY(80px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: config.slow,
    delay: 200,
  });

  const ImageWrapper = ({ children }) => {
    return <animated.div style={imageSpring}>{children}</animated.div>;
  };

  return (
    <Section
      title={t['UseTheDataToYourAdvantage']}
      titleProps={{ font: 'h1', color: 'darkBlue', lineHeight: '70px', css: titleCss }}
      description={t['UnderstandingYourCustomerHas']}
      descriptionProps={{ css: textCss }}
      ImgWrapper={ImageWrapper}
      img={isPortuguese ? homeHeroPt : homeHeroEn}
      imgAlt="Angora Labs"
      contentColProps={{
        md: 24,
        lg: 10,
        xl: 10,
      }}
      imgColProps={{
        md: 24,
        lg: 13,
        xl: 13,
      }}
      css={headerCss}
      imageCss={heroCss}
      imageColCss={imageColCss}
      minHeight="781.83px"
      gutter={[0, 64]}
    >
      <Space height={32} />
      <ButtonWrapper align="middle" gutter={[32, 32]}>
        <Col md={24} lg={24} xl={11}>
          <Row justify="center">
            <a
              href="https://www.producthunt.com/posts/angoralabs-home-1?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-angoralabs-home-1"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=209112&theme=light"
                alt="AngoraLabs - Home - Start tracking for free! | Product Hunt Embed"
                style={{ height: 48, width: 220 }}
              />
            </a>
          </Row>
        </Col>
        <Col md={24} lg={24} xl={10}>
          <Row justify="center">
            <Button type="primary" style={{ width: 220 }} onClick={() => history.push('/contact')}>
              {t['GetStartedForFree']}
            </Button>
          </Row>
        </Col>
      </ButtonWrapper>
    </Section>
  );
};

export default HeroSection;
