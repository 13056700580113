import React from 'react';
import styled from 'styled-components';

import SEO, { SEOProps } from '../../SEO/SEO';

import HeroSection from './HeroSection';
import MoreDataSection from './MoreDataSection';
import UserSegmentation from './UserSegmentation';
import FilterSection from './FilterSection';
import DataDrivenSection from './DataDrivenSection';
import ListSection from './ListSection';
import JourneySection from './JourneySection';
import CrmSection from './CrmSection';

const WhiteBackground = styled.div`
  background: #fff;
  width: 100%;
`;

interface HomeProps {
  seo: SEOProps;
}

const Home = ({ seo }: HomeProps) => {
  return (
    <>
      <HeroSection />
      <WhiteBackground>
        <MoreDataSection />
        <UserSegmentation />
        <FilterSection />
        <DataDrivenSection />
        <ListSection />
        <JourneySection />
        <CrmSection />
      </WhiteBackground>

      <SEO {...seo} />
    </>
  );
};

export default Home;
