import React from 'react';
import styled, { css } from 'styled-components';

import useTranslation from '../../../locales/useTranslation';

import Section from '../../common/Section';

import journeySectionPt from '../../../images/journeySectionPt.png';
import journeySectionEn from '../../../images/journeySectionEn.png';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const GreenBackground = styled.div`
  background: linear-gradient(0deg, #76bbca, #76bbca);
  border-radius: 20px;
  position: absolute;
  left: 48px;
  right: 48px;
  top: 0;
  bottom: 0;

  @media (max-width: 992px) {
    border-radius: 0;
    left: 0;
    right: 0;
  }
`;

const journeyCss = css`
  margin-right: 56px;
  padding: 60px 0px 32px;
  position: relative;
  z-index: 2;
  flex-wrap: wrap-reverse !important;
  margin: 0 !important;

  @media (max-width: 992px) {
    margin-right: 0;
    padding: 72px 32px 50px 0;
  }
`;

const contentColCss = css`
  padding-right: 96px;
  @media (max-width: 992px) {
    padding-right: 0;
    padding-left: 32px;
  }
`;

const titleCss = css`
  @media (max-width: 992px) {
    font-size: 35px;
    line-height: 35px;
    text-align: center !important;
  }
`;

const textCss = css`
  @media (max-width: 992px) {
    text-align: center !important;
  }
`;

const JourneySection = () => {
  const { t, isPortuguese } = useTranslation();

  return (
    <Wrapper>
      <Section
        sectionTitle={t['Analysis']}
        sectionTitleProps={{ color: 'white', css: textCss }}
        title={t['KnowYourUsersJourney']}
        titleProps={{ color: 'green', css: titleCss }}
        description={t['UnderstandWhereTheyCameFrom']}
        descriptionProps={{ color: 'white', css: textCss }}
        img={isPortuguese ? journeySectionPt : journeySectionEn}
        imgAlignment="left"
        imgColProps={{
          md: 24,
          lg: 11,
          xl: 11,
        }}
        contentColProps={{
          md: 24,
          lg: 12,
          xl: 12,
        }}
        gutter={[0, 32]}
        css={journeyCss}
        contentColCss={contentColCss}
      />
      <GreenBackground />
    </Wrapper>
  );
};

export default JourneySection;
