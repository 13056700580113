import React from 'react';
import styled, { css } from 'styled-components';
import { Row, Col } from 'antd';
import Lottie from 'react-lottie';

import useTranslation from '../../../locales/useTranslation';

import Text from '../../common/Text';
import Space from '../../common/Space';

import churn from '../../../lotties/churnIcon.json';
import dev from '../../../lotties/devIcon.json';
import ux from '../../../lotties/uxIcon.json';
import sales from '../../../lotties/salesIcon.json';

const Container = styled(Row).attrs({
  justify: 'space-between',
  align: 'middle',
})`
  padding: 150px 74px 77px 48px;
  margin: 0 !important;

  @media (max-width: 992px) {
    padding: 100px 27px 82px;
  }
`;

const Icon = styled(Row).attrs({
  justify: 'center',
  align: 'middle',
})`
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(17, 30, 66, 0.1);
  border-radius: 20px;
  min-height: 77px;
  min-width: 77px;
`;

const Item = styled(Row).attrs({
  align: 'top',
})`
  flex-wrap: unset !important;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const titleCss = css`
  width: 100%;
  @media (max-width: 992px) {
    font-size: 35px;
    line-height: 35px;
    text-align: center !important;
  }
`;

const textCss = css`
  width: 100%;
  @media (max-width: 992px) {
    text-align: center !important;
  }
`;

const ListSection = () => {
  const { t } = useTranslation();

  const list = [
    { icon: churn, title: t['ForThoseWhoWantToReduceChurn'], description: t['SegmentYourCustomersByType'] },
    { icon: dev, title: t['DirectDevelopmentTeamEfforts'], description: t['UnderstandWhichPartsOfThePlatform'] },
    {
      icon: ux,
      title: t['ImproveCustomerExperience'],
      description: t['GetValuableInsightsFromUserJourneyAnalysis'],
    },
    { icon: sales, title: t['ImproveSales'], description: t['GroupNewVisitorsAccordingToTheirCharacteristics'] },
  ];

  return (
    <Container gutter={[0, 64]}>
      <Col md={24} lg={8} xl={8}>
        <Row>
          <Text font="h2" color="darkBlue" css={titleCss}>
            {t['WhoIsAngoraFor']}
            <Text font="h2" color="pink" span css={titleCss}>
              ?
            </Text>
          </Text>
        </Row>
      </Col>

      <Col md={24} lg={14} xl={14}>
        {list.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && <Space height={70} />}
            <Item gutter={[0, 16]}>
              <Col>
                <Icon>
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: item.icon,
                      rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
                    }}
                    height={57}
                    width={57}
                  />
                </Icon>
              </Col>
              <Space width={24} />
              <Col>
                <Text font="sectionTitle" css={textCss}>
                  {item.title}
                </Text>
                <Space height={16} />
                <Text font="link" color="lightBlue" css={textCss}>
                  {item.description}
                </Text>
              </Col>
            </Item>
          </React.Fragment>
        ))}
      </Col>
    </Container>
  );
};

export default ListSection;
